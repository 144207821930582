import React, { useEffect, useState, useCallback } from "react";
import { apiKey, host } from "../../settings";
import { isValidEmail, isValidPhone } from "../../utils/validationUtils";
import SubmitButton from "../submitButton";

const ContactForm = ({ data, privacy, theme }) => {
  const initialValues = {
    salutation: "",
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    period: "",
    destination: "",
    passengers: 0,
    children: 0,
    cabin: "",
    message: "",
    checkPrivacyPolicy: false,
    travelAgency: "",
  };

  const [isEnabled, setIsEnabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [values, setValues] = useState(initialValues);
  const [touched, setTouched] = useState({});
  const [errors, setErrors] = useState({});

  const isValid = Object.keys(errors).length === 0;

  const validateForm = useCallback(() => {
    const errors = {};

    if (values.salutation.length === 0) {
      errors.salutation = "Aanspreektitel *";
    }

    if (values.firstName.length === 0) {
      errors.firstName = "Voornaam *";
    }

    if (values.lastName.length === 0) {
      errors.lastName = "Naam *";
    }

    if (values.phone.length === 0) {
      errors.phone = "Telefoonnummer *";
    }

    if (!isValidPhone(values.phone)) {
      errors.phone = "Telefoonnummer *";
    }

    if (values.period.length === 0) {
      errors.period = "Periode *";
    }

    if (values.destination.length === 0) {
      errors.destination = "Bestemming *";
    }

    if (values.passengers <= 0) {
      errors.passengers = "Passagiers *";
    }

    if (values.children < 0) {
      errors.children = "Kinderen *";
    }

    if (!isValidEmail(values.email)) {
      errors.email = "Email *";
    }

    if (!values.checkPrivacyPolicy) {
      errors.checkPrivacyPolicy = "checkbox privacy policy *";
    }

    setErrors(errors);
  }, [
    values.salutation.length,
    values.firstName.length,
    values.lastName.length,
    values.phone,
    values.email,
    values.period.length,
    values.destination.length,
    values.passengers,
    values.children,
    values.cabin,
    values.message.length,
    values.checkPrivacyPolicy,
  ]);

  useEffect(() => {
    validateForm();
  }, [values, validateForm]);

  const sendmail = mail => {
    return (async () => {
      setIsEnabled(false);
      setIsLoading(true);

      const url = `${host}/api/web/mail`;

      try {
        await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Api-Key": apiKey,
          },
          body: JSON.stringify(mail),
        });
        setIsSent(true);
        setIsLoading(false);
        setIsEnabled(true);
      } catch (error) {
        setIsLoading(false);
        setIsEnabled(true);
        console.error(error);
      }
    })();
  };

  const handleSubmit = e => {
    e.preventDefault();

    setTouched(
      Object.keys(values).reduce((prev, cur) => {
        prev[cur] = true;
        return prev;
      }, {})
    );

    if (isValid) {
      const emailToSend = {
        firstName: values.firstName,
        lastName: values.lastName,
        emailAddress: values.email,
        phone: values.phone,
        emailTemplate: data.content.general.conformationEmailTemplate?.key,
        message: `
                <p>Nieuwe reisvoorkeuren van ${values.salutation} ${values.lastName} ${values.firstName}:<br>
                <ul>
                <li>Bestemming: ${values.destination}</li>
                <li>Periode: ${values.period}</li>
                <li>Kajuit: ${values.cabin}</li>
                <li>Aantal volwassenen: ${values.passengers}</li>
                <li>Kinderen: ${values.children}</li>
                <li>Extra opmerkingen: ${values.message}</li>
                ${
                  theme == "select-together" ? `<li>Ik wens te boeken via ${values.travelAgency}</li>` : `<br>`
                }                
                <li>Tel: ${values.phone}</li>
                <li>E-mail: ${values.email}</li>
                </ul>`,
      };

      sendmail(emailToSend);
    }
  };

  const handleBlur = e => {
    setTouched(
      Object.assign({}, touched, {
        [e.target.name]: true,
      })
    );
  };

  const handleChange = e => {
    e.persist();
    if (e.target.type === "checkbox") {
      values[e.target.name] = e.target.checked;
      setValues(values);
      validateForm();
    } else {
      setValues(
        Object.assign({}, values, {
          [e.target.name]: e.target.value,
        })
      );
    }
  };

  return (
    <div className="form-wrapper">
      <h2>{data.content.general.title}</h2>
      <form className="form">
        <div className="form__row form__row--salutation">
          <div className="form__col">
            <label className={`form__label ${touched.salutation && errors.salutation ? "form__label--error" : ""}`}>
              Aanspreektitel *
            </label>
            <div className="radio-buttons">
              <div className="radio-button">
                <input
                  id="sir"
                  type="radio"
                  name="salutation"
                  value="dhr"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <label htmlFor="sir">Dhr</label>
              </div>
              <div className="radio-button">
                <input id="mrs" type="radio" name="salutation" value="mw" onChange={handleChange} onBlur={handleBlur} />
                <label htmlFor="mrs">Mw</label>
              </div>
              <div className="radio-button">
                <input
                  id="different"
                  type="radio"
                  name="salutation"
                  value="anders"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <label htmlFor="different">Anders</label>
              </div>
            </div>
          </div>
        </div>

        <div className="form__row">
          <div className="form__col form__col--50">
            <label htmlFor="firstName">
              <span className={`form__label ${touched.firstName && errors.firstName ? "form__label--error" : ""}`}>
                Voornaam *
              </span>
            </label>
            <input
              id="firstName"
              className={`form__input ${touched.firstName && errors.firstName ? "form__input--error" : ""}`}
              type="text"
              name="firstName"
              value={values.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div className="form__col form__col--50">
            <label htmlFor="lastName">
              <span className={`form__label ${touched.lastName && errors.lastName ? "form__label--error" : ""}`}>
                Naam *
              </span>
            </label>
            <input
              id="lastName"
              className={`form__input ${touched.lastName && errors.lastName ? "form__input--error" : ""}`}
              type="text"
              name="lastName"
              value={values.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div className="form__col form__col--50">
            <label htmlFor="phone">
              <span className={`form__label ${touched.phone && errors.phone ? "form__label--error" : ""}`}>
                Telefoonnummer *
              </span>
            </label>
            <input
              id="phone"
              className={`form__input ${touched.phone && errors.phone ? "form__input--error" : ""}`}
              type="text"
              name="phone"
              value={values.phone}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div className="form__col form__col--50">
            <label htmlFor="email">
              <span className={`form__label ${touched.email && errors.email ? "form__label--error" : ""}`}>
                E-mailadres *
              </span>
            </label>
            <input
              id="email"
              className={`form__input ${touched.email && errors.email ? "form__input--error" : ""}`}
              type="email"
              name="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>

        <div className="form__row">
          <div className="form__col form__col--50">
            <label htmlFor="period">
              <span className={`form__label ${touched.period && errors.period ? "form__label--error" : ""}`}>
                Reisperiode *
              </span>
            </label>
            <input
              id="period"
              className={`form__input ${touched.period && errors.period ? "form__input--error" : ""}`}
              type="text"
              name="period"
              value={values.period}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div className="form__col form__col--50">
            <label htmlFor="destination">
              <span className={`form__label ${touched.destination && errors.destination ? "form__label--error" : ""}`}>
                Bestemming *
              </span>
            </label>
            <input
              id="destination"
              className={`form__input ${touched.destination && errors.destination ? "form__input--error" : ""}`}
              type="text"
              name="destination"
              value={values.destination}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>

        <div className="form__row">
          <div className="form__col form__col--50 form__col--md-33">
            <label htmlFor="passengers">
              <span className={`form__label ${touched.passengers && errors.passengers ? "form__label--error" : ""}`}>
                Aantal volwassenen *
              </span>
            </label>
            <input
              id="passengers"
              className={`form__input ${touched.passengers && errors.passengers ? "form__input--error" : ""}`}
              type="number"
              name="passengers"
              value={values.passengers}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div className="form__col form__col--50 form__col--md-33">
            <label htmlFor="children">
              <span className={`form__label ${touched.children && errors.children ? "form__label--error" : ""}`}>
                Aantal kinderen *
              </span>
            </label>
            <input
              id="children"
              className={`form__input ${touched.children && errors.children ? "form__input--error" : ""}`}
              type="number"
              name="children"
              value={values.children}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div className="form__col form__col--md-33">
            <label htmlFor="cabin">
              <span className={`form__label ${touched.cabin && errors.cabin ? "form__label--error" : ""}`}>
                Type kajuit
              </span>
            </label>
            <input
              id="cabin"
              className={`form__input ${touched.cabin && errors.cabin ? "form__input--error" : ""}`}
              type="text"
              name="cabin"
              value={values.cabin}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>

        <div className="form__row">
          <div className="form__col">
            <label htmlFor="message">
              <span className="form__label">Opmerkingen</span>
            </label>
            <textarea
              id="message"
              type="text"
              name="message"
              value={values.message}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </div>

        {theme == "select-together" && (
          <div className="form__row">
            <div className="form__col">
              <label htmlFor="travelAgency">
                <span className="form__label">Ik wens te boeken via volgend reisbureau:</span>
              </label>
              <input
                id="travelAgency"
                className="form__input"
                type="text"
                name="travelAgency"
                value={values.travelAgency}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          </div>
        )}

        <div className="form__row">
          <div className="form__col">
            <label className="checkbox">
              <input
                id="checkPrivacyPolicy"
                className={`checkbox__input ${
                  touched.checkPrivacyPolicy && errors.checkPrivacyPolicy ? "checkbox__input--error" : ""
                }`}
                type="checkbox"
                name="checkPrivacyPolicy"
                value={values.checkPrivacyPolicy}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <span className="checkbox__label">
                Ik ga akkoord met de{" "}
                <a href={`/${privacy}` ?? "/"} target="_blank">
                  privacy policy *
                </a>
                .
              </span>
            </label>
          </div>
        </div>
        {Boolean(Object.keys(touched).filter(key => Boolean(errors[key])).length) && (
          <div className="form__row form__row--alter">
            <div className="form__col">
              <div className="error">Volgende velden zijn niet correct ingevuld. Kijk deze even na:</div>
            </div>
            <div className="form__col form__col--errors">
              {Object.keys(touched)
                .filter(key => Boolean(errors[key]))
                .map((item, index) => (
                  <label className="errorlabel" key={index}>
                    {errors[item]}
                  </label>
                ))}
            </div>
          </div>
        )}
        {Boolean(isSent) && (
          <div className="form__row">
            <div className="form__col">
              <label className="successlabel">Uw aanvraag is verstuurd, bedankt!</label>
            </div>
          </div>
        )}
        <div className="form__row">
          <SubmitButton
            text="Verstuur reisaanvraag"
            isLoading={isLoading}
            isEnabled={isEnabled}
            onClick={handleSubmit}
            isSecondary={false}
          />
        </div>
      </form>
    </div>
  );
};
export default ContactForm;
