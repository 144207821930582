import React from "react";

export default ({ data }) => {
  if (!data.content.general) {
    return null;
  }

  // data.content should be filled in, others require null check. If validation is wrong return null (if data is empty)
  const blockdata = data.content.general; // data.content.informatie && data.content.informatie.vertrekdata;

  return (
    Boolean(blockdata) && (
      <section className="page-block-2columntextwithtitle">
        <div className="page-block-2columntextwithtitle__container">
          <div className="page-block-2columntextwithtitle__header">
            <h2>{blockdata.title}</h2>
          </div>
          <div className="page-block-2columntextwithtitle__row">
            <div
              className="page-block-2columntextwithtitle__col"
              dangerouslySetInnerHTML={{ __html: blockdata.text1 }}
            />
            <div
              className="page-block-2columntextwithtitle__col"
              dangerouslySetInnerHTML={{ __html: blockdata.text2 }}
            />
          </div>
        </div>
      </section>
    )
  );
};
