import React from "react";
import HighlightedTextblock from "../highlighted-textblock";

export default ({ data, zeroMargingBottom }) => {
  // data.content should be filled in, others require null check. If validation is wrong return null (if data is empty)
  const blockdata = data.content.general; // data.content.informatie && data.content.informatie.vertrekdata;

  if (!blockdata) {
    return null;
  }

  return (
    <HighlightedTextblock image={blockdata.image?.url} zeroMargingBottom={zeroMargingBottom} isTiny={blockdata.isTiny}>
      <h2>{blockdata.title}</h2>
      <div className="page-block-2columntextwithsidetitle__col" dangerouslySetInnerHTML={{ __html: blockdata.text }} />
    </HighlightedTextblock>
  );
};
