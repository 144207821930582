import React from "react";
import { addWidthParameter } from "../../utils/addWidthParameter";

export default ({ data }) => {
  // data.content should be filled in, others require null check. If validation is wrong return null (if data is empty)
  const blockdata = data.content.general;
  const layout = data.content.layout;

  let className = "page-block-largeimagesidetext";

  if (!(blockdata && layout)) {
    return null;
  }

  if (Boolean(layout.textPositionLeft)) {
    className += " page-block-largeimagesidetext--left";
  }

  return (
    <section className={className}>
      <div className="page-block-largeimagesidetext__container">
        <div className="page-block-largeimagesidetext__image">
          <img src={addWidthParameter(blockdata.image?.url, 1600)} alt={blockdata.image.altText} />
        </div>
        <div className="page-block-largeimagesidetext__wrapper">
          <div className="page-block-largeimagesidetext__body">
            <div className="page-block-largeimagesidetext__title">
              <h2>{blockdata.title}</h2>
            </div>
            <div
              className="page-block-largeimagesidetext__description"
              dangerouslySetInnerHTML={{ __html: blockdata.text }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};
