import React from "react";
import { addWidthParameter } from "../utils/addWidthParameter";

export default ({ children, image, zeroMargingBottom, isTiny }) => (
  // <section className="highlighted-textblock">
  <section className={`highlighted-textblock ${isTiny ? "highlighted-textblock--16-4" : ""}`}>
    <div
      className="highlighted-textblock__container"
      style={{ backgroundImage: `url('${addWidthParameter(image, 3460)}')`, marginBottom: zeroMargingBottom }}
    >
      <div className="highlighted-textblock__content">
        <div className="highlighted-textblock__card">{children}</div>
      </div>
    </div>
  </section>
);
