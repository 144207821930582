import { add, endOfYear, format, startOfDay } from "date-fns";
import { Link } from "gatsby";
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import PageBlock2columntextwithtitle from "../../components/page-blocks/page-block-2columntextwithtitle";
import PageBlockContact from "../../components/page-blocks/page-block-contact";
import PageBlockHeader from "../../components/page-blocks/page-block-header";
import PageBlockLargeimagesidetext from "../../components/page-blocks/page-block-largeimagesidetext";
import PageBlockParagraphwithsidebar from "../../components/page-blocks/page-block-paragraphwithsidebar";
import PageBlockTextpageblockhighlight from "../../components/page-blocks/page-block-textpageblockhighlight";
import SeoHeaders from "../../components/seo/seo-headers";
import GlobalContext from "../../contexts/global-provider";
import { environment } from "../../environments/environment";
import { apiKey, catalogueId, host, websiteName } from "../../settings";
import Layout from "../layout";

const ShippingCompanyWildcard = ({
  pageContext: { page, shippingCompany, cmsProducts, navigation, cruiseTypes, privacy },
}) => {
  
  const {setNavigation, setPrivacy, theme} = useContext(GlobalContext);
  setNavigation(navigation);
  setPrivacy(privacy);

  const dateFormat = "yyyy/MM/dd";
  const data = {
    content: {
      general: {
        title: `Aanbiedingen met ${shippingCompany?.company?.name}`,
        isShippingCompanies: true,
        max: 3,
        shippingCompany: shippingCompany?.company,
      },
      paragraph: {
        content: {
          paragraph: {
            text: shippingCompany.company.content.paragraph.text,
          },
          sidebar: {
            text: shippingCompany.company.content.paragraph.sidebarText,
          },
          layout: {
            paragraphPositionLeft: shippingCompany.company.content.paragraph.paragraphPositionLeft,
          },
        },
      },
      highlight: {
        content: {
          general: {
            title: shippingCompany?.company?.content?.highlight?.title,
            text: shippingCompany?.company?.content?.highlight?.text,
            image: shippingCompany?.company?.content?.highlight?.image,
            isTiny: true,
          },
        },
      },
      targetAudience: {
        content: {
          general: {
            title: shippingCompany?.company?.content?.targetAudience?.title,
            text1: shippingCompany?.company?.content?.targetAudience?.text1,
            text2: shippingCompany?.company?.content?.targetAudience?.text2,
          },
        },
      },
      destination: {
        content: {
          general: {
            title: shippingCompany?.company?.content?.destination?.title,
            text: shippingCompany?.company?.content?.destination?.text,
            image: shippingCompany?.company?.content?.destination?.image,
          },
          layout: {
            textPositionLeft: shippingCompany?.company?.content?.destination?.textPositionLeft,
          },
        },
      },
      mood: {
        content: {
          general: {
            title: shippingCompany?.company?.content?.mood?.title,
            text: shippingCompany?.company?.content?.mood?.text,
            image: shippingCompany?.company?.content?.mood?.image,
            textPositionLeft: shippingCompany?.company?.content?.mood?.textPositionLeft,
          },
          layout: {
            textPositionLeft: shippingCompany?.company?.content?.mood?.textPositionLeft,
          },
        },
      },
      fleet: {
        content: {
          general: {
            title: shippingCompany?.company?.content?.fleet?.title,
            text: shippingCompany?.company?.content?.fleet?.text,
            image: shippingCompany?.company?.content?.fleet?.image,
            textPositionLeft: shippingCompany?.company?.content?.fleet?.textPositionLeft,
          },
          layout: {
            textPositionLeft: shippingCompany?.company?.content?.fleet?.textPositionLeft,
          },
        },
      },
      contact: {
        content: {
          general: {
            title: shippingCompany?.company?.content?.contact?.title,
            sendTo: shippingCompany?.company?.content?.contact?.sendTo,
            conformationEmailTemplate: shippingCompany?.company?.content?.contact?.conformationEmailTemplate_Thalassa,
          },
        },
      },
    },
  };

  if (theme === "select-together") {
    data.content.contact.content.general.conformationEmailTemplate =
      shippingCompany?.company?.content?.contact?.conformationEmailTemplate_SelectTogether;
  }

  const [companyProducts] = useState(
    cmsProducts.filter(
      c =>
        c.content?.general?.shippingCompany &&
        c.content.general.shippingCompany.itemId === shippingCompany?.company?.itemId
    )
  );
  const [showProductButton, setShowProductButton] = useState(false);

  useEffect(() => {
    const fetchProducts = () => {
      const selectedMinDate = startOfDay(new Date());
      const selectedMaxDate = endOfYear(add(new Date(), { years: 2 }));
  
      const searchRequest = {
        officeId: environment.builds[0].officeId,
        payload: {
          catalogueIds: [catalogueId],
          rooms: [
            {
              index: 0,
              pax: [
                {
                  age: 25
                },
                {
                  age: 25
                }
              ]
            }
          ],
          serviceType: 11,
          searchType: 1,
          fromDate: format(selectedMinDate, dateFormat),
          toDate: format(selectedMaxDate, dateFormat),
          productIds: companyProducts.filter(c => !!c.content.general.product?.key).map(c => c.content.general.product.key)
        }
      };
    
      const url = `${host}/api/web/booking/v2/search`;
      return fetch(url, {
        method: "POST",
        headers: {
          "Api-Key": apiKey,
          "Content-Type": "application/json",
          "Language": "nl-BE"
        },
        body: JSON.stringify(searchRequest),
      });
    };

    (async () => {
      if (companyProducts && companyProducts.length) {
        const productsResponse = await fetchProducts();
        const foundProducts = await productsResponse.json();
        if (foundProducts && foundProducts.length) {
          setShowProductButton(true);
        }
      }
    })();
  }, [companyProducts]);

  if (!shippingCompany) {
    return <></>;
  }

  // Output

  return (
    <Layout
      isTiny={true}
      hasHeader={true}
      showQsm={Boolean(page.content.layout.qsm)}
      isTinted={Boolean(page.content.layout.backgroundTinted)}
      navigation={navigation}
      cruiseTypes={cruiseTypes}
      privacy={privacy}
    >
      <SeoHeaders
        seo={shippingCompany.company.content.seo}
        pageTitle={
          shippingCompany.company.content.seo && shippingCompany.company.content.seo.title
            ? shippingCompany.company.content.seo.title
            : page.content.general.title
        }
        pagePath={page.content.general.path}
        siteName={websiteName[theme]}
      />
      <Helmet>
        <title>
          {shippingCompany.company.content.seo && shippingCompany.company.content.seo.title
            ? shippingCompany.company.content.seo.title
            : page.content.general.title}
        </title>
      </Helmet>
      <PageBlockHeader data={shippingCompany.company} />
      <PageBlockParagraphwithsidebar data={data.content.paragraph} />
      {showProductButton && shippingCompany?.company?.itemId && (
        <div className="page-block-button__container">
          <div className="page-block-button__cta page-block-button__cta--right">
            <Link
              to={`${environment.builds[0].url}products?shippingCompany=${shippingCompany.company.itemId}`}
              target="_blank"
            >
              Toon reizen van deze rederij
            </Link>
          </div>
        </div>
      )}
      <PageBlockTextpageblockhighlight data={data.content.highlight} zeroMargingBottom="0" />
      <PageBlock2columntextwithtitle data={data.content.targetAudience} />
      <PageBlockLargeimagesidetext data={data.content.destination} />
      <PageBlockLargeimagesidetext data={data.content.mood} />
      <PageBlockLargeimagesidetext data={data.content.fleet} />
      <PageBlockContact data={data.content.contact} privacy={privacy} theme={theme} />
    </Layout>
  );
};

export default ShippingCompanyWildcard;
